import { default as HowToEarnXq1F4D8QxVMeta } from "/build/pages/attractions/_components/HowToEarn.vue?macro=true";
import { default as PartnerBrands9gcFnjQkdYMeta } from "/build/pages/attractions/_components/PartnerBrands.vue?macro=true";
import { default as PriceCompareCardIGga0yXGoWMeta } from "/build/pages/attractions/detail/[countryCode]/[cityName]/_components/PriceCompareCard.vue?macro=true";
import { default as _91name_93_46_91locale_93_46htmlOluU8DAWHgMeta } from "/build/pages/attractions/detail/[countryCode]/[cityName]/[name].[locale].html.vue?macro=true";
import { default as indexBQRhwV0wJVMeta } from "/build/pages/attractions/index.vue?macro=true";
import { default as ListItemm8PLatWrlwMeta } from "/build/pages/attractions/list/[countryCode]/[cityName]/_components/ListItem.vue?macro=true";
import { default as ListTopPQEJB4qQhfMeta } from "/build/pages/attractions/list/[countryCode]/[cityName]/_components/ListTop.vue?macro=true";
import { default as _91locale_93_46htmlPujTPx5rBMMeta } from "/build/pages/attractions/list/[countryCode]/[cityName]/[locale].html.vue?macro=true";
import { default as component_45stubG0HCo7YJXMMeta } from "/build/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_rollup@4.20.0_sass@1.77.8_typescript@5.5.4_vite@5.4.0_vue-tsc@2.0.29/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubG0HCo7YJXM } from "/build/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_rollup@4.20.0_sass@1.77.8_typescript@5.5.4_vite@5.4.0_vue-tsc@2.0.29/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "attractions-_components-HowToEarn",
    path: "/attractions/_components/HowToEarn",
    component: () => import("/build/pages/attractions/_components/HowToEarn.vue").then(m => m.default || m)
  },
  {
    name: "attractions-_components-PartnerBrands",
    path: "/attractions/_components/PartnerBrands",
    component: () => import("/build/pages/attractions/_components/PartnerBrands.vue").then(m => m.default || m)
  },
  {
    name: "attractions-detail-countryCode-cityName-_components-PriceCompareCard",
    path: "/attractions/detail/:countryCode()/:cityName()/_components/PriceCompareCard",
    component: () => import("/build/pages/attractions/detail/[countryCode]/[cityName]/_components/PriceCompareCard.vue").then(m => m.default || m)
  },
  {
    name: "attractions-detail-countryCode-cityName-name.locale.html",
    path: "/attractions/detail/:countryCode()/:cityName()/:name().:locale().html",
    component: () => import("/build/pages/attractions/detail/[countryCode]/[cityName]/[name].[locale].html.vue").then(m => m.default || m)
  },
  {
    name: "attractions",
    path: "/attractions",
    component: () => import("/build/pages/attractions/index.vue").then(m => m.default || m)
  },
  {
    name: "attractions-list-countryCode-cityName-_components-ListItem",
    path: "/attractions/list/:countryCode()/:cityName()/_components/ListItem",
    component: () => import("/build/pages/attractions/list/[countryCode]/[cityName]/_components/ListItem.vue").then(m => m.default || m)
  },
  {
    name: "attractions-list-countryCode-cityName-_components-ListTop",
    path: "/attractions/list/:countryCode()/:cityName()/_components/ListTop",
    component: () => import("/build/pages/attractions/list/[countryCode]/[cityName]/_components/ListTop.vue").then(m => m.default || m)
  },
  {
    name: "attractions-list-countryCode-cityName-locale.html",
    path: "/attractions/list/:countryCode()/:cityName()/:locale().html",
    component: () => import("/build/pages/attractions/list/[countryCode]/[cityName]/[locale].html.vue").then(m => m.default || m)
  },
  {
    name: component_45stubG0HCo7YJXMMeta?.name,
    path: "/",
    component: component_45stubG0HCo7YJXM
  }
]