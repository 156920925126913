import { defineStore } from 'pinia'
import type { AttrDestinationDto } from '~/server/types/city.type'

const dayjs = useDayjs()
interface FilterConditions {
  /* 时间戳 */
  date?: number
  detailDate?: number
  selectedCity?: AttrDestinationDto
  tagList?: number[]
  listTagList?: number[]
}

export const defaultTicketName = '1 Adult'

export const useFilterConditionsStore = defineStore('filter-conditions', () => {
  const filterConditions: FilterConditions = reactive({
    date: new Date().getTime(), // 日期 时间戳
    detailDate: new Date().getTime(),
    selectedCity: {
      destinationId: 454,
      destinationName: 'Singapore',
      countryCode: 'sg',
    }, // 用户票型
    listTagList: [],
    tagList: [], // 票型
  })

  const getRequestFilterConditions = () => {
    return {
      destinationId: filterConditions.selectedCity?.destinationId,
      tagIdList: filterConditions.tagList,
      date: dayjs(filterConditions.date).format('YYYY-MM-DD'),
      detailDate: dayjs(filterConditions.detailDate).format('YYYY-MM-DD'),
    }
  }

  watch(
    () => filterConditions.date,
    () => {
      filterConditions.detailDate = filterConditions.date
    },
  )
  const filterDateRef = computed(() => ({
    get: (isDetail: boolean) => (isDetail ? filterConditions.detailDate : filterConditions.date),
    set: (isDetail: boolean, newValue: number) => {
      if (isDetail) {
        filterConditions.detailDate = newValue
      }
      else {
        filterConditions.date = newValue
      }
    },
  }))

  const filterTagListRef = computed(() => ({
    get: (isDetail: boolean) =>
      isDetail ? filterConditions.tagList : filterConditions.listTagList,
    set: (isDetail: boolean, newValue: any[]) => {
      if (isDetail) {
        filterConditions.tagList = newValue
      }
      else {
        filterConditions.listTagList = newValue
      }
    },
  }))

  return { filterConditions, getRequestFilterConditions, filterTagListRef, filterDateRef }
}, {
  persist: true,
})
