// 自定义 fetch 插件
export default defineNuxtPlugin(() => {
  const fetch = $fetch.create({
    // 请求添加用户信息
    onRequest({ options }) {
      const store = useUserStore()
      const userInfo = store.getRowUserInfo()

      options.body = Object.assign({}, options.body, userInfo)
    },
  })

  return {
    provide: {
      fetch,
    },
  }
})
