<script setup lang="ts">
const userStore = useUserStore()
const commonStore = useCommonStore()

initParamsFromQuery()

if (!userStore.userInfo.accessToken) {
  await userStore.fetchLoginToken()
}
commonStore.getIndexConfig()

function initParamsFromQuery() {
  const { query } = useRoute()

  if (query) {
    if (query.disCode) {
      userStore.userInfo.disCode = query.disCode as string
    }
    if (query.memberId) {
      userStore.userInfo.memberId = query.memberId as string
    }
  }
}
</script>

<template>
  <NuxtLoadingIndicator />
  <NuxtRouteAnnouncer />
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<style>
:root:root {
  --van-popup-close-icon-color: #121212;
}
</style>
