import type { UseFetchOptions } from '#app'

export function useAppFetch<T>(
  url: string,
  options: UseFetchOptions<T> = {},
) {
  return useFetch(url, {
    ...options,
    $fetch: useNuxtApp().$fetch,
  })
}
