import revive_payload_client_BGS5UyD9Cz from "/build/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_rollup@4.20.0_sass@1.77.8_typescript@5.5.4_vite@5.4.0_vue-tsc@2.0.29/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_bmq7GhyizA from "/build/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_rollup@4.20.0_sass@1.77.8_typescript@5.5.4_vite@5.4.0_vue-tsc@2.0.29/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_3lyr8Xm5Lz from "/build/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_rollup@4.20.0_sass@1.77.8_typescript@5.5.4_vite@5.4.0_vue-tsc@2.0.29/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_qpirMR8AQF from "/build/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_rollup@4.20.0_sass@1.77.8_typescript@5.5.4_vite@5.4.0_vue-tsc@2.0.29/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_dayCJtMyU0 from "/build/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_rollup@4.20.0_sass@1.77.8_typescript@5.5.4_vite@5.4.0_vue-tsc@2.0.29/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_14GyqeQdTi from "/build/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_rollup@4.20.0_sass@1.77.8_typescript@5.5.4_vite@5.4.0_vue-tsc@2.0.29/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UchkxRGkML from "/build/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_rollup@4.20.0_sass@1.77.8_typescript@5.5.4_vite@5.4.0_vue-tsc@2.0.29/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_zlQMkhB9en from "/build/node_modules/.pnpm/@pinia+nuxt@0.5.3_rollup@4.20.0_typescript@5.5.4_vue@3.4.37/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/build/.nuxt/components.plugin.mjs";
import prefetch_client_b3gRw9vciG from "/build/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_rollup@4.20.0_sass@1.77.8_typescript@5.5.4_vite@5.4.0_vue-tsc@2.0.29/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import vant_lazyload_plugin_W13KNvxip6 from "/build/.nuxt/vant-lazyload.plugin.mjs";
import plugin_NiDlde2VFX from "/build/node_modules/.pnpm/dayjs-nuxt@2.1.9_rollup@4.20.0/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import switch_locale_path_ssr_f4gtl4N964 from "/build/node_modules/.pnpm/@nuxtjs+i18n@8.3.3_rollup@4.20.0_vue@3.4.37/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_q6ELgViU4R from "/build/node_modules/.pnpm/@nuxtjs+i18n@8.3.3_rollup@4.20.0_vue@3.4.37/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import unocss_MzCDxu9LMj from "/build/.nuxt/unocss.mjs";
import plugin_IACZbg2VJW from "/build/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.3_pinia@2.2.2_rollup@4.20.0/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import fetch_14DdzPiXUB from "/build/plugins/fetch.ts";
export default [
  revive_payload_client_BGS5UyD9Cz,
  unhead_bmq7GhyizA,
  router_3lyr8Xm5Lz,
  payload_client_qpirMR8AQF,
  navigation_repaint_client_dayCJtMyU0,
  check_outdated_build_client_14GyqeQdTi,
  chunk_reload_client_UchkxRGkML,
  plugin_vue3_zlQMkhB9en,
  components_plugin_KR1HBZs4kY,
  prefetch_client_b3gRw9vciG,
  vant_lazyload_plugin_W13KNvxip6,
  plugin_NiDlde2VFX,
  switch_locale_path_ssr_f4gtl4N964,
  i18n_q6ELgViU4R,
  unocss_MzCDxu9LMj,
  plugin_IACZbg2VJW,
  fetch_14DdzPiXUB
]