import { defineStore } from 'pinia'
import type { ILoginRes } from '~/server/types/login.type'

export const useUserStore = defineStore('userInfo', () => {
  const userInfo = reactive({
    memberId: '',
    disCode: 'azgo',
    accessToken: '',
    currency: 'USD',
    locale: 'en-us',
  })

  const getRowUserInfo = () => {
    return toRaw(userInfo)
  }

  async function fetchLoginToken() {
    const { data } = await useAppFetch<ILoginRes>('/api/login', {
      method: 'POST',
    })
    if (data) {
      userInfo.accessToken = data.value?.accessToken || ''
      return true
    }
  }

  return { userInfo, getRowUserInfo, fetchLoginToken }
})
